import { Component, OnInit, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../services/api.service';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Product } from '../models/product';
import { CartService } from '../services/cart.service';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {

  durationInSeconds = 5;
  group: string;
  products: any[]
  imgSrc: any;
  images: any;
  selected_product: Product;
  snackBarRef: any;


  constructor(
    private activatedRouter: ActivatedRoute,
    private apiService: ApiService,
    private dialog: MatDialog,
    private storage: AngularFireStorage,
    private cart: CartService,
    private snackBar: MatSnackBar,
    private route: Router
  ) { }

  ngOnInit(): void {
    this.images = {}
    this.group = this.activatedRouter.snapshot.paramMap.get("category")
    this.apiService.getGames(this.group).valueChanges().subscribe(products => {
      this.products = products
      this.products.forEach(item => {
        this.getImage(item.id).subscribe(url => this.images[item.id] = url)
      })
    })

    this.cart.initializeCart()

  }

  getImageUrl(img: string) {
    return this.images[img]
  }

  getImage(img: string) {
    const ref = this.storage.ref('productes/' + img + '.jpg');
    return ref.getDownloadURL()
  }

  openInfo(templateRef: TemplateRef<any>, product: Product) {
    this.addItem(product)
    this.selected_product = product;

    this.dialog.open(templateRef, {
      height: '400px',
      width: '600px',
      panelClass: 'info'
    });
  }

  addItem(product: Product) {
    this.cart.addItem(product).subscribe(r => {
      this.snackBarRef = this.snackBar.open(`El ${product.name} s'ha afegit a la cistella`, 'Anar a la cistella', {
        duration: this.durationInSeconds * 1000
      })


      this.snackBarRef.onAction().subscribe(() => {
        this.route.navigateByUrl('/cart')
      });
    })
  }


}
