<div class=' min-h-screen bg-gray-50 py-6 justify-center relative overflow-hidden sm:py-12'>
    <a href="/home">Comprar més</a>
    <h2>Productes a la cistella</h2>

    <div *ngFor='let product of products' class=''>
        {{product.name}}
        <img width='50' src="{{product.image}}" />

        <div class="example-button-container">
            <button (click)="subQuantity(product)">
                <hero-icon [name]="'minus-circle'" [type]="'solid'" [class]="'w-7 h-7'"></hero-icon>

            </button>
            {{product.quantity}}
            <button (click)="sumQuantity(product)">
                <hero-icon [name]="'plus-circle'" [type]="'solid'" [class]="'w-7 h-7'"></hero-icon>
            </button>
        </div>

        <p>Price: {{product.price}}</p>

        <button (click)='deleteItem(product.id)'>Esborrar</button>
    </div>

    <p>Import Total: {{total_price}} €</p>
    <p>Despeses d'enviament: 5€</p>
    <p>Total Iva Inclés: sumar enviaemtn + total_price</p>


    <p>
        <a href="/checkout">Procedir a la compra</a>
    </p>
</div>