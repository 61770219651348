<div class="font-sans antialiased h-screen">
    <!-- Top Navigation -->
    <app-admin-header></app-admin-header>

    <div id="main" class="pt-16">
        <!-- Sidebar -->
        <app-admin-sidebar></app-admin-sidebar>

        <!-- Content -->
        <div class="bg-white h-full pl-8 pt-8">
            <p>
                <button (click)='updateAge()'>Update Age</button>

            </p>
            <p>
                <button (click)='updateProductsCounter()'>Update Products counter</button>

            </p>

        </div>


    </div>
</div>