<div class="dark bg-gray-900 print:hidden ng-tns-c50-30 w-48 ">
  <div class="xl:py-2">
    <div class="hidden xl:block uppercase font-bold text-grey-darker text-xs px-4 py-2">
      <hero-icon [name]="'menu'" [type]="'solid'" [class]="'w-4 h-4 text-red-900'"></hero-icon>
      Menú
    </div>
    <div class="group relative sidebar-item with-children">
      <a href="/admin/dashboard"
        class="block xl:flex xl:items-center text-center xl:text-left shadow-light xl:shadow-none py-6 xl:py-2 xl:px-4 border-l-4 border-transparent hover:bg-black">
        <div class="text-white text-xs">Dashboard</div>
      </a>
    </div>
    <div class="group relative sidebar-item with-children">
      <a href="/admin/products/list"
        class="block xl:flex xl:items-center text-center xl:text-left shadow-light xl:shadow-none py-6 xl:py-2 xl:px-4 border-l-4 border-blue-dark xl:opacity-75">

        <div class="text-purple-600 text-xs">Productes</div>
      </a>

    </div>
    <div class="group relative sidebar-item with-children">
      <a href="/admin/products/tags"
        class="block xl:flex xl:items-center text-center xl:text-left shadow-light xl:shadow-none py-6 xl:py-2 xl:px-4 border-l-4 border-blue-dark xl:opacity-75">

        <div class="text-purple-600 text-xs">Tags</div>
      </a>

    </div>


  </div>
  <!-- Secondary Menu -->
  <div class="xl:py-2">
    <div class="hidden xl:block uppercase font-bold text-grey-darker text-xs px-4 py-2">
      Secondary
    </div>
    <div class="group relative sidebar-item with-children">
      <a href="/admin/products/batch"
        class="block xl:flex xl:items-center text-center xl:text-left shadow-light xl:shadow-none py-6 xl:py-2 xl:px-4 border-l-4 border-blue-dark xl:opacity-75">

        <div class="text-purple-600 text-xs">Batch actions</div>
      </a>

    </div>
    <div class="group relative sidebar-item with-children">
      <a href="#"
        class="block xl:flex xl:items-center text-center xl:text-left shadow-light xl:shadow-none py-6 xl:py-2 xl:px-4 border-l-4 border-transparent hover:bg-black">

        <div class="text-white text-xs">Bookmarks</div>
      </a>
    </div>

  </div>
  <!-- Other Links -->
  <div class="py-4">
    <div class="hidden xl:block uppercase font-bold text-grey-darker text-xs px-4 py-2">
      Action
    </div>
    <div class="px-2">
      <a href="#"
        class="flex items-center justify-center py-2 w-full text-xs text-center text-white block bg-blue hover:bg-blue-dark rounded shadow-light font-bold">

        Button
      </a>
    </div>
  </div>
</div>