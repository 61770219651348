import { Component, OnInit } from '@angular/core';
import { Product } from '../../models/product';
import { CartService } from '../../services/cart.service';
import { DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { CartItem } from 'src/app/models/cartItem';
import { AuthService } from 'src/app/services/auth.service';


@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {

  products: CartItem[]
  total_price = 0;

  constructor(
    private cartService: CartService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.reloadProducts()
    this.authService.currentUser().subscribe(r => console.log(r))
  }

  reloadProducts() {
    this.cartService.getListCart().subscribe(cart => {
      this.products = cart;
      this.recalculatePrice()
    })
  }

  recalculatePrice(): void {
    this.total_price = 0
    this.products.forEach(i => {
      this.total_price = this.total_price + (i.price * i.quantity)
    })
  }

  deleteItem(productId) {
    this.cartService.deleteItem(productId)
    this.recalculatePrice()

  }

  subQuantity(item: CartItem) {
    this.cartService.substractItemFromCart(item).subscribe(r => this.reloadProducts())
  }

  sumQuantity(item: CartItem) {
    this.cartService.addItemFromCart(item).subscribe(r => this.recalculatePrice())
  }

}
