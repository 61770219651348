import { Injectable } from '@angular/core';
import { Product } from 'src/app/models/product';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { Cart, CartItem } from 'src/app/models/cartItem';
import { map } from 'rxjs/operators';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { ApiService } from './api.service';
import * as uuid from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  cartItems = [];
  count = 0;

  products = new BehaviorSubject([]);

  cartUser: AngularFirestoreDocument<Cart>
  collection: any;

  private cart: Observable<Cart>

  constructor(
    private firestore: AngularFirestore,
    private apiService: ApiService
  ) {
    this.initializeCart()
  }

  initializeCart() {

    if (localStorage.getItem('cartId') == null) {
      localStorage.setItem('cartId', uuid.v4())
    }

    this.cartUser = this.firestore.collection<Cart>('cart').doc(localStorage.getItem('cartId'))
    console.log('cartId', localStorage.getItem('cartId'))
    return localStorage.getItem('cartId')

  }

  getCartObservable() {
    return this.cart
  }

  addItem(product: Product): Observable<any> {
    const items = this.cartUser.collection('items')
    return this.checkIfExistsItem(product).pipe(
      map(productCart => {
        if (productCart) {
          return this.addExistingItemToCart(items, product, productCart)

        } else {
          console.log('add itenm')
          return this.addNewItemToCart(items, product)

        }
      }))
  }

  private addExistingItemToCart(items, product: Product, productCart) {
    return this.apiService.getImage(product.id).subscribe(r => {
      items.doc(product.id).update({
        'quantity': productCart.quantity + 1
      })
    })
  }

  /* TODO pendent implementar */
  private substractExistingItemToCart(items, product: Product, productCart) {

    const quantity = productCart.quantity - 1

    return this.apiService.getImage(product.id).subscribe(r => {
      items.doc(product.id).update({
        'quantity': productCart.quantity - 1
      })
    })
  }

  private addNewItemToCart(items, product: Product) {
    return this.apiService.getImage(product.id).subscribe(r => {
      items.doc(product.id).set({
        'id': product.id,
        'name': product.name,
        'image': r,
        'quantity': 1,
        'price': product.price
      })
    })
  }

  checkIfExistsItem(product: Product) {
    const items = this.cartUser.collection('items')
    return items.doc(product.id).get().pipe(
      map(r => {
        console.log('item', r.data()); return r.data()
      }))

  }

  getListCart(): Observable<any[]> {
    const items = this.cartUser.collection('items')
    return items.valueChanges()
  }

  deleteItem(id): Promise<void> {
    const items = this.cartUser.collection('items')
    return items.doc(id).delete()
  }

  addItemFromCart(item: CartItem) {
    const items = this.cartUser.collection('items')

    return of(items.doc(item.id).update({
      'quantity': item.quantity + 1
    }))

  }

  substractItemFromCart(item: CartItem) {
    const items = this.cartUser.collection('items')

    return of(items.doc(item.id).update({
      'quantity': item.quantity - 1
    }))
  }





  /*
      return this.firestore.collection<CartItem[]>('cart', ref =>
        ref.where('id', '==', 'zlUS3iVoJUj7Vn7KPeSi').pipe(
          map(res => {
            console.log(res)
          })
        )
      }
  */
  /*



  return this.http.get<CartItem[]>(cartUrl).pipe(
    map(res  => {
      let response: any[]=[];
      let cartItems: CartItem[]=[];

      response = Object.keys(res).map((key) => { return res[key] });
      console.log(response)
      for (let item of response){
        //console.log('item from firebase: ',item)
        let itemAvailable = false;

        for (let i in cartItems){
          if(cartItems[i].productId === item.productItem.id) {
            cartItems[i].qty ++;
            itemAvailable = true;
            break;
          }
        }
        
        if (!itemAvailable){
          cartItems.push(new CartItem(item.id,item.productItem))
          
        }
        
      }
     
      return cartItems;
    })
  );
}
*/
  /*getProductFromCart(): Observable<CartItem[]>{
    return this.http.get<CartItem[]>(cartUrl).pipe(
      map((result: any[])=>{
        console.log(result);
        
        let cartItems: CartItem[]=[];

        for (let item of result){
          let itemAvailable = false;

          for (let i in cartItems){
            if(cartItems[i].productId === item.productItem.id) {
              cartItems[i].qty ++;
              itemAvailable = true;
              break;
            }
          }
          
          if (!itemAvailable){
            cartItems.push(new CartItem(item.id,item.productItem))
            
          }
          
        }
        return cartItems;
      })
    );
  }*/
}
