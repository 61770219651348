import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { from, Observable, of } from 'rxjs';
import { Category } from '../models/category';
import { Product } from '../models/product';
import { ProductModel } from '../admin/products/product.model';
import { ImageModel } from '../models/image.model';
import { Tag } from '../models/tag';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class ApiService {

  data$: Observable<any>;
  games: any;

  constructor(
    private firestore: AngularFirestore,
    private storage: AngularFireStorage
  ) { }


  public getCategories() {
    return this.firestore.collection<Category>('category', ref => ref.orderBy('order', 'asc'))
  }


  public getGames(category) {

    if (category == 'jocs_taula_adults') {
      return this.firestore.collection<Product>('games', ref =>
        ref.where('es_adults', '==', 1).
          where('stock', '==', 1).
          orderBy('order', 'asc'))
    } else if (category == 'novetats') {
      return this.firestore.collection('games', ref =>
        ref.where('es_novetat', '==', 1).
          where('stock', '==', 1).
          orderBy('order', 'asc'))
    } else {


      return this.firestore.collection('games', ref =>
        ref.where('category', '==', category).
          where('stock', '==', 1).
          orderBy('order', 'asc'))
    }

  }

  getImage(img: string) {
    const ref = this.storage.ref('productes/' + img + '.jpg');
    return ref.getDownloadURL()
  }

  getImages(product: ProductModel) {
    return this.firestore.collection<ImageModel>('images', ref => ref.where('product_id', '==', product.id)).valueChanges({ 'idField': 'id' })
  }

  countProducts(): Observable<any> {
    return this.firestore.collection("counters").doc('products').valueChanges()
  }

  getProductsList(rows: number, item: Product | null): Observable<any> {
    let productCollection = this.firestore.collection<Product>("games", ref =>
      ref.orderBy('name')
        .startAfter(item)
        .limit(rows))

    return productCollection.snapshotChanges()
    
  }

  getProduct(id): Observable<ProductModel> {
    return this.firestore.collection<ProductModel>('games').doc(id).valueChanges()
  }

  updateProduct(product: ProductModel) {
    return of(this.firestore.collection<ProductModel>('games').doc(product.id).update(
      product
    ))
  }

  initializeCounter(): void {
    this.firestore.collection("games").snapshotChanges().subscribe(t => this.updateCounters('products', t.length))

  }

  searchProducts(text) {
    this
  }
  updateCounters(document: string, number: number): Observable<any> {
    return of(this.firestore.collection('counters').doc(document).set({
      'number': number
    }))
  }

  deleteProduct(product: ProductModel) {
    return of(this.firestore.collection<ProductModel>('games').doc(product.id).delete())
  }

  createProduct(product: ProductModel) {
    return of(this.firestore.collection<ProductModel>('games').doc(product.id).set(
      product
    ))
  }

  addImage(productId: string, downloadUrl: string) {
    return this.firestore.collection('images').doc().set({
      'product_id': productId,
      'description': '',
      'url': downloadUrl
    })
  }

  async deleteImage(image: ImageModel) {
    console.log('id', image.id)
    await this.firestore.collection('images').doc(image.id).delete();
    return from(this.storage.refFromURL(image.url).delete());
  }

  updateAges(ages) {
    for (let age of ages) {
      Object.keys(age).forEach(r => {
        this.firestore.collection('masters').doc('age').collection('ages').doc(r).set({
          'name': age[r],
          'id': r
        })

      })
    }
  }

  getAges() {
    return this.firestore.collection('masters').doc('age').collection('ages')
  }

  getTags() {
    return this.firestore.collection('masters').doc('tag').collection('tags')
  }

  getMasterList(listName) {
    return this.firestore.collection('masters').doc(listName).collection(listName)

  }

  addItemToMasterList(listName, item) {
    let itemId = item.toLowerCase().trim().replace(' ', '_')

    return of(this.firestore.collection('masters').doc(listName).collection(listName).doc(itemId).set({
      'name': item
    }))
  }

  addTag(tag: string) {
    let tagId = tag.toLowerCase().trim().replace(' ', '_')

    return of(this.firestore.collection('masters').doc('tag').collection('tags').doc(tagId).set({
      'name': tag
    }))
  }

  deleteTag(tag: Tag) {
    let tagId = tag.name.toLowerCase().trim().replace(' ', '_')

    return of(this.firestore.collection('masters').doc('tag').collection('tags').doc(tagId).delete())
  }



}

