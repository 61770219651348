<div class="background">
    <div class="login-container">
      <div class="text-container">
        <h1>Hola!</h1>
        <p>
          Cal que entris a l'aplicació amb el teu usuari o bé et creis un de nou.
        </p>
        <button mat-button routerLink="/register">Sign up</button>
      </div>
      <div class="form-container">
        <h1>Benvingut a PucEduca</h1>
        <button class="mat-raised-button" (click)="loginWithGoogle()">
          Entra amb Google
        </button>
        <h5>O entra amb el teu email</h5>
        <ngbytes-login-form (formData)="login($event)"></ngbytes-login-form>
      </div>
    </div>
  </div>