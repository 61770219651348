import { Component, OnInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { LoginData } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-login-page',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {

  returnUrl: string;

  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
    private route: ActivatedRoute

  ) {}

  ngOnInit(): void {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

  }

  login(loginData: LoginData) {
    this.authService
      .login(loginData)
      .then((r) => {
        this.router.navigate(['/'])
      })
      .catch((e) => console.log(e.message));
  }

  loginWithGoogle() {
    this.authService
      .loginWithGoogle()
      .then(() => this.router.navigate(['/']))
      .catch((e) => console.log(e.message));
  }

}