<nav class="navbar navbar-expand-lg navbar-light p-2  sticky-top ">
    <!--
    <div class="navbar-collapse collapse w-100 order-1 order-md-0 dual-collapse2">

            <ul class="navbar-nav mr-auto">
                
                <li class="nav-item">
                    <a class="nav-link" href="//codeply.com">Codeply</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#">Link</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#">Link</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#">Link</a>
                </li>
            </ul>
        </div>
    -->

    <div class="navbar w-100 order-3 dual-collapse2">
        <ul class="navbar-nav ml-auto">
            <li class="nav-item active">
                <a href='/home' class=' brand'><img src='/assets/img/logo_cataleg_nadal.png'></a>
            </li>   
            <li class="nav-item active">
                <a href='/cart' class=' brand'>Carret</a>
            </li>
        </ul>
    </div>

    <mat-toolbar><a (click)="logout()">Log out</a></mat-toolbar>

</nav>
