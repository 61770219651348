<footer class='container-fluid mt-3'>
    <div class='row p-2'>
        <div class='col'>
            <p>Assessorament, comandes, cita prèvia, dubtes, ...</p>
        </div>
    </div>
    <div class='whatsapp-float'>
        <a href="https://api.whatsapp.com/send?phone=+34622433899&text=Hola! " target="_blank">
            <img width='50px' src='/assets/img/whatsapp.png'>

        </a>
    </div>
</footer>