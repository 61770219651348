<!--
    <div class='w-100 arbrets sticky-top'></div>
-->

<div class=' min-h-screen bg-gray-50 py-6 justify-center relative overflow-hidden sm:py-12'>

    <div class="grid grid-cols-1 lg:grid-cols-4 lg:gap-4 mx-10">

        <div *ngFor='let product of products' class=''>
            <img (click)='openInfo(info, product)' class='img-fluid' [src]="getImageUrl(product.id)">
            <div class="flex content-between">
                <p class='text-lg font-semibold text-puceduca'>{{product.price}}€</p>
                <p>
                    <button (click)='addItem(product)'>
                        Afegir a la cistella
                    </button>
                </p>
            </div>
        </div>
    </div>
</div>

<ng-template #info>
    <div class='row'>

        <div class='col-8' matDialogTitle>{{selected_product.name}}</div>
        <p class='text-lg font-semibold text-puceduca'>{{selected_product.price}}€</p>
        <div class='col'>
            <button (click)='addItem(selected_product)'>
                Afegir a la cistella
            </button>
        </div>
    </div>

    <mat-dialog-content>

        <ul class=" list-group ">
            <li *ngIf="selected_product.age " class="list-group-item row ">
                <div class=" col ">
                    <img src="/assets/img/icons/age.png " class="img-fluid " alt="Edad "> {{selected_product.age}}
                </div>
            </li>

            <li *ngIf="selected_product.size " class="list-group-item row ">
                <div class=" col ">
                    <img src="/assets/img/icons/size.png " class="img-fluid " alt="Mida "> {{selected_product.size}}
                </div>
            </li>


            <li *ngIf="selected_product.players " class="list-group-item row ">
                <div class=" col ">
                    <img src="/assets/img/icons/players.png " class="img-fluid " alt="Jugadors ">
                    {{selected_product.players}}
                </div>
            </li>

            <li *ngIf="selected_product.playtime " class="list-group-item row ">
                <div class=" col ">
                    <img src="/assets/img/icons/play_time.png " class="img-fluid " alt="Temps ">
                    {{selected_product.playtime}}
                </div>
            </li>

        </ul>

    </mat-dialog-content>
    <mat-dialog-actions>
        <button mat-button matDialogClose color="primary">TANCAR</button>
    </mat-dialog-actions>
</ng-template>