<div class="flex items-center justify-center h-screen bg-gray-200">
    <div class="container">
        <h2 class="p-5 bg-puceduca mx-auto">

            <img class='mx-auto' src="/assets/img/logo_cataleg_nadal.png">
        </h2>
        <div class="bg-white rounded-lg shadow-lg p-5 md:p-20 mx-2">
            <div class="text-center">

                <h3 class='text-xl md:text-3xl mt-10'>Estem refent la web i tornarem aviat!</h3>
                <!-- <p class="text-md md:text-xl mt-10"><a class="hover:underline" href="https://www.quicktoolz.com">Quicktoolz</a> is a website help you provide simple productivity tools for your daily need online for free.</p> -->
            </div>
            <div class="flex flex-wrap mt-10 justify-center">
                <div class="m-3">
                    <a href="https://instagram.com/puceduca" title="Puceduca a Instagram"
                        class="md:w-32 bg-white tracking-wide text-gray-800 font-bold rounded border-2 border-blue-500 hover:border-blue-500 hover:bg-blue-500 hover:text-white shadow-md py-2 px-6 inline-flex items-center">
                        <span class="mx-auto">Instagram</span>
                    </a>
                </div>
                <div class="m-3">
                    <a href="https://facebook.com/puceduca/" title="Puceduca a Facebook"
                        class="md:w-32 bg-white tracking-wide text-gray-800 font-bold rounded border-2 border-red-600 hover:border-red-600 hover:bg-red-600 hover:text-white shadow-md py-2 px-6 inline-flex items-center">
                        <span class="mx-auto">Facebook</span>
                    </a>
                </div>

            </div>
        </div>
    </div>
</div>