<!--
    <div class='w-100 arbrets sticky-top'></div>
-->
<div class=' min-h-screen bg-gray-50 py-6 justify-center relative overflow-hidden sm:py-12'>

    <div class="grid grid-cols-4 gap-2 mx-10">


        <div *ngFor="let category of categories | async" class='px-6 pt-10 pb-8 bg-white shadow-xl ring-1 ring-gray-900/5 sm:max-w-lg sm:mx-auto sm:rounded-lg sm:px-10'>
            <a (click)='openCategory(category.id)'>
                <img style='cursor: pointer;' class='img-fluid' mat-card-image
                    src="/assets/img/categories/{{category.img}}" alt="{{category.name}}">

            </a>
        </div>
    </div>



</div>