<div class="background">
    <div class="login-container">
      <div class="form-container">
        <h1>Welcome to NgBytes Register</h1>
        <h5>Register with your email</h5>
        <ngbytes-login-form (formData)="register($event)"></ngbytes-login-form>
      </div>
      <div class="text-container">
        <h1>Hey Buddy</h1>
        <p>
          Already have an awesome account? Go ahead and click the button below to
          sign in!
        </p>
        <button mat-button routerLink="/">Sign in</button>
      </div>
    </div>
  </div>