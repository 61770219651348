import { Component, OnInit, ɵisListLikeIterable } from '@angular/core';
import { ApiService } from "src/app/services/api.service";

@Component({
  selector: 'app-batch',
  templateUrl: './batch.component.html',
  styleUrls: ['./batch.component.scss']
})
export class BatchComponent implements OnInit {

  constructor(private apiService: ApiService) { }

  ngOnInit(): void {
  }

  public updateAge() {

    const ages = [

      { 'de_0_a_2_anys': 'de 0 a 2 anys' },
      { 'a_partir_de_12_mesos': 'a partir de 12 mesos' },
      { 'a_partir_de_18_mesos': 'a partir de 18 mesos' },
      { 'mes_de_2_anys': 'mes de 2 anys' },
      { 'de_3_a_5_anys': 'de 3 a 5 anys' },
      { 'mes_de_3_anys': 'mes de 3 anys' },
      { 'de_5_a_8_anys': 'de 5 a 8 anys' },
      { 'de_4_a_7_anys': 'de 4 a 7 anys' },
      { 'a_partir_de_4_anys': 'a partir de 4 anys' },
      { 'a_partir_de_5_anys': 'a partir de 5 anys' },
      { 'de_8_a_12_anys': 'de 8 a 12 anys' },
      { 'mes_de_6_anys': 'mes de 6 anys' },
      { 'de_8_a_10_anys': 'de 8 a 10 anys' },
      { 'a_partir_de_8_anys': 'a partir de 8 anys' },
      { 'mes_de_10_anys': 'mes de 10 anys' },
      { 'mes_de_12_anys': 'mes de 12 anys' },
      { 'mes_de_8_anys': 'mes de 8 anys' },
      { 'mes_de_14_anys': 'mes de 14 anys' },
      { 'mes_de_16_anys': 'mes de 16 anys' },
      { 'joves_i_adolescents': 'joves i adolescents' },
      { 'adults': 'adults' },
      { 'ideal_per_a_gent_gran': 'ideal per a gent gran' },
      { 'mes_de_7_anys': 'mes de 7 anys' },
      { 'de_4_a_8_anys': 'de 4 a 8 anys' },
      { 'de_3_a_4_anys': 'de 3 a 4 anys' },
      { 'de_5_a_7_anys': 'de 5 a 7 anys' },
      { 'de_4_a_6_anys': 'de 4 a 6 anys' }
    ]

    this.apiService.updateAges(ages)
  }

  public updateProductsCounter() {

    this.apiService.initializeCounter()
  }

  public updateSearchProducts() {
    // load all products name strings


    // trencar acada nom per espais..


    // guardar en index 

    // documents('search').doc(1234).

    // 
  }

  public createKeywords(name) {
    const arrName = [];
    let curName = '';

    name.split('').forEach(letter => {
      curName += letter;
      arrName.push(curName)

    })

    return arrName
  }

}
