import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { CartComponent } from './checkout/cart/cart.component';
import { HomeComponent } from './home/home.component';
import { ProductsComponent } from './products/products.component';
import { UnderConstructionComponent } from './under-construction/under-construction.component';

const routes: Routes = [
  //{ path: '', redirectTo: '/home', pathMatch: 'full' }, // redirect to `first-component` 
  { path: '', component: UnderConstructionComponent }, // redirect to `first-component` 
  { path: 'home', component: HomeComponent },
  { path: 'productes/:category', component: ProductsComponent },
  { path: 'cart', component: CartComponent },
  {
    path: 'auth',
    loadChildren: () =>
      import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'user',
    loadChildren: () =>
      import('./user/user.module').then((m) => m.UserModule),
  },
  {
    path: 'admin',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./admin/admin.module').then((m) => m.AdminModule)
  },
  {
    path: 'checkout',
    canActivate: [],
    loadChildren: () =>
      import('./checkout/checkout.module').then((m) => m.CheckoutModule)
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
