import { Injectable, NgZone } from '@angular/core';
import {
  Auth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  signInWithPopup,
  GoogleAuthProvider
} from '@angular/fire/auth';


import { AngularFireAuth } from "@angular/fire/compat/auth";
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { User, LoginData } from '../models/user';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})


export class AuthService {

  userData: any;

  constructor(
    private auth: AngularFireAuth,
    private firestore: AngularFirestore,
    public router: Router,
    public ngZone: NgZone // NgZone service to remove outside scope warning
  ) {
    this.auth.authState.subscribe(user => {
      if (user) {
        this.userData = user
        localStorage.setItem('user', JSON.stringify(this.userData))
        JSON.parse(localStorage.getItem('user'));

      } else {
        localStorage.setItem('user', null);
        JSON.parse(localStorage.getItem('user'));
      }
    })
  }

  login({ email, password }: LoginData) {
    return this.auth.signInWithEmailAndPassword(email, password)
  }

  register({ email, password }: LoginData) {
    return this.auth.createUserWithEmailAndPassword(email, password);
  }

  logout() {
    return this.auth.signOut().then(() => {
      localStorage.removeItem('user');
      this.router.navigate(['sign-in']);
    })
  }

  loginWithGoogle() {
    return this.authLogin(new GoogleAuthProvider());
  }

  authLogin(provider) {
    return this.auth.signInWithPopup(provider)
      .then((result) => {
        this.setUserData(result.user);
        this.ngZone.run(() => {
          this.router.navigate(['/']);
        })
      }).catch((error) => {
        window.alert(error)
      })
  }

  setUserData(user) {
    const userRef: AngularFirestoreDocument<any> = this.firestore.doc(`users/${user.uid}`);
    const userData: User = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      emailVerified: user.emailVerified
    }
    return userRef.set(userData, {
      merge: true
    })
  }


  currentUser() {
    return this.auth.user
  }

  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    return (user !== null && user.emailVerified !== false) ? true : false;
  }

  hasDirection(user: User) {
    const userRef: AngularFirestoreDocument<any> = this.firestore.doc(`users/${user.uid}`);

    console.log(userRef)


  }
}