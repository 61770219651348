<div class="flex flex-nowrap font-sans antialiased h-screen">
    <!-- Top Navigation -->

    <app-admin-sidebar></app-admin-sidebar>
    <app-admin-header></app-admin-header>

    <div class=' min-h-screen bg-gray-50 py-6 justify-center relative overflow-hidden sm:py-12'>

        <div class="grid grid-cols-1 lg:grid-cols-4 lg:gap-4 mx-10">
            <mat-card class="mt-20">
                <mat-card-header>
                    <div mat-card-avatar class="example-header-image"></div>
                    <mat-card-title>Productes</mat-card-title>
                    <mat-card-subtitle>Llistat de productes</mat-card-subtitle>
                </mat-card-header>

                <mat-card-content>
                    <div class="flex">

                        <a class='' href="/admin/products/list">Veure la llista</a>
                        <a class= '' href="/admin/products/create">Afegir nou producte</a>
                    </div>
                </mat-card-content>
                <mat-card-actions>
                   
                </mat-card-actions>
            </mat-card>
        </div>
    </div>
</div>