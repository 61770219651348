import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Category } from '../models/category';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  categories: Observable<Category[]>;

  constructor(
    private apiService: ApiService,
    private router: Router) {


  }

  ngOnInit(): void {
    this.categories = this.apiService.getCategories().valueChanges()

    /*
    this.apiService.getCategories()
    this.apiService.categories$.subscribe(results => {
      this.categories = results
    })
    
    this.apiService.getCategories().subscribe(results => {
      console.log(results);
      this.categories = results
    })
    */

    /*
    this.apiService.getCategories().valueChanges().subscribe(categories => {
      this.categories = categories
    })
*/
  }

  openCategory(category) {
    return this.router.navigateByUrl(`/productes/${category}`)
  }

}
